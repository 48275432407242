/* eslint-disable no-nested-ternary */
import React, { lazy, Suspense, useEffect, useState } from 'react';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
// import { ErrorBoundary } from 'react-error-boundary';
import { fetchAndActivate, getValue } from "firebase/remote-config";

// mui theme
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation } from "react-router-dom";
import { useNavigationType } from "react-router";
import ThemeProvider from './theme';
// contexts
import { AppProvider } from './contexts/AppContext';
import { AuthProvider } from './contexts/AuthContext';
import { IdleProvider } from './contexts/IdleProvider';
// hooks
import { useServiceWorker } from './hooks/useServiceWorker.ts';
import useResponsive from './hooks/useResponsive';
import ErrorFallback from './components/error/ErrorFallback';
// Error Boundary
// Loading Spinner component
import LoadingSpinner from './components/micro/LoadingSpinner';
import { remoteConfig } from './configs/firesBaseConfig';
import SplashScreen from './components/splash/SplashScreen';
import useLocalStorage from './hooks/useLocalStorage';


// Lazy-loaded components
const Router = lazy(() => import('./routes'));
const ScrollToTop = lazy(() => import('./components/micro/ScrollToTop'));
const NotistackProvider = lazy(() => import('./components/others/NotistackProvider'));
const Settings = lazy(() => import('./components/settings'));

// javascript-time-ago initialized
TimeAgo.addDefaultLocale(en);

// -----------------------------------------------

export default function App() {
    // Calling below hook registers service worker
    // eslint-disable-next-line no-unused-vars
    const serviceworker = useServiceWorker();
    const [isSplashVisible, setIsSplashVisible] = useState(true);
    const isDesktop = useResponsive('up', 'lg');
    const [splashData, setSplashData] = useState({
        splash_icon: '', text_message: '', splash_bg: '',
    });
    const [showSplash, setShowSplash] = useLocalStorage('splashShown');

    if (!window.__SENTRY_INITIALIZED__) {
        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                Sentry.replayIntegration(),
            ],
            // Tracing
            environment: process.env.REACT_APP_ENV,
            tracesSampleRate: 1.0, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [process.env.REACT_APP_HOST],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        window.__SENTRY_INITIALIZED__ = true;
    }

    const getSplashData = () => {
        fetchAndActivate(remoteConfig)
            .then(() => {
                const configValue = getValue(remoteConfig, "custom_splash_screen").asString();
                const splashConfig = JSON.parse(configValue);
                if (splashConfig) {
                    setSplashData(splashConfig);
                }
            })
            .catch((err) => {
                console.error("Error fetching or parsing remote config value:", err);
            });
    };

    // Check if splash was shown before
    function handleSplashTimeout(currentTime, setIsSplashVisible, setShowSplash) {
        setTimeout(() => {
            setIsSplashVisible(false);
            // Set the shown time to 1 hour in the future (3600000 milliseconds)
            setShowSplash(currentTime + 3600000);
        }, 3000);
    }

    useEffect(() => {
        getSplashData();
        const currentTime = Date.now();
        // Check if it's been more than one hour since last splash
        if (showSplash === undefined) {
            // Splash screen has never been shown
            handleSplashTimeout(currentTime, setIsSplashVisible, setShowSplash);
        } else if (currentTime > showSplash) {
            // More than one hour since the last splash screen
            handleSplashTimeout(currentTime, setIsSplashVisible, setShowSplash);
        } else {
            // Splash screen is still within the one-hour limit
            setIsSplashVisible(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fallbackRender = ({ resetError }) => (
        <ErrorFallback
            resetError={resetError}
        />
    );

    return (
        <div>
            <ThemeProvider>
              <AppProvider>
                <Sentry.ErrorBoundary fallback={fallbackRender}>
                    {isSplashVisible && !isDesktop && (
                        <SplashScreen
                            messageData={splashData.text_message}
                            imageURL={splashData.splash_icon}
                            bgMobileSplash={splashData.splash_bg}
                        />
                    )}
                    {!isSplashVisible && (
                        <NotistackProvider>
                            <AuthProvider>
                                <IdleProvider>
                                    <Settings />
                                    <ScrollToTop />
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <Suspense fallback={<LoadingSpinner />}>
                                            <Router />
                                        </Suspense>
                                    </LocalizationProvider>
                                </IdleProvider>
                            </AuthProvider>
                        </NotistackProvider>
                    )}
                </Sentry.ErrorBoundary>
                </AppProvider>
            </ThemeProvider>
        </div>
    );
}