import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useEffect, useMemo, useState } from 'react';

// analytics
import { getAnalytics, setUserId } from "firebase/analytics";

// import { AES } from 'crypto-js';
import hmacsha256 from 'crypto-js/hmac-sha256';


// hook
import useApi from '../hooks/api/useApi';
import useLocalStorage from '../hooks/useLocalStorage';
//
import { endpoints } from '../configs/api';
import { PATH_AUTH } from '../routes/paths';

import { userIdHashKey } from '../configs/firesBaseConfig';

// -----------------------------------------------

const initialState = {
  auth: null,
  facilities: [],
  selectedFacility: null,
};

const DEFAULT_FACILITY = {
  id: "",
  name: "",
  avatar: null,
  integration_type: "QR",
}

const AuthContext = createContext({
  ...initialState,
  setAuth: () => { },
  getOTP: () => { },
  verifyOTP: () => { },
  resendOTP: () => { },
  login: () => { },
  logout: () => { },
  resetAuth: () => { },
  setSelectedFacility: () => { },
  createFacility: () => { },
});

// -----------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

const analytics = process.env.REACT_APP_ENV ==='dev'?
    console.log("Disabled in dev mode"):getAnalytics();

function AuthProvider({ children }) {
  const { enqueueSnackbar } = useSnackbar();
  const { fetchResponse } = useApi();

  // const [state, dispatch] = useReducer(reducer, initialState);

  const [auth, setAuth] = useLocalStorage('auth', null);

  // facilities
  const [facilities, setFacilities] = useState([]);
  // const [facilities, setFacilities] = useLocalStorage('facilities');
  const [selectedFacility, setSelectedFacility] = useLocalStorage('selectedFacility');




  // --------- OTP --------
  const getOTP = (phone) => {

    const payload = {
      "phone_number": phone,
    }

    return fetchResponse(endpoints.getOTP, 'POST', payload)
      .then(res => {
        // console.log(res)
        if (res.status === 200) {
          return true;
        }
        throw new Error('Unable to send the OTP');
      })

  };

  const resendOTP = (phone) => {

    const payload = {
      "phone_number": phone,
    }

    return fetchResponse(endpoints.resendOTP, 'POST', payload)
      .then(res => {
        // console.log(res)
        if (res.status === 200) {
          return true;
        }
        throw new Error('Unable to send the OTP');
      })

  };

  const verifyOTP = (payload) => fetchResponse(endpoints.verifyOTP, 'POST', payload)
    .then(async (response) => {

      const { data } = response;

      if (data.token) {

        setAuth({ ...data });

        if(userIdHashKey){
          const hashkey = await userIdHashKey.then(key => key)

          const hash = hmacsha256(data?.user_id, hashkey).toString();
  
          await setUserId(analytics, hash);
        }

       

        // console.log("loger146", data);

        if (data?.user_type !== "SOFTWARE_ADMIN" && data.facility && data.facility.length > 0) {
          // console.log("loger147,", data.facility);
          // setFacilities(data.facility.map(e => ({ ...DEFAULT_FACILITY, ...e })));
          // select first facility if nothing is selected
          if (!selectedFacility) {
            setSelectedFacility({ ...data.facility[0] });
          }
        }

        return data.token;
      }

      throw new Error(data?.message || 'Unable to sign in.');

    }).catch(err => {
      console.error('c/ac/180', err.message);
      throw new Error(err);
    });
  // --------- OTP --------


  const logout = (api = true, callbackUrl = null) => {
    // console.log("loger 204 logout()");
    if (!api) {
      resetAuth(callbackUrl);
      return;
    }

    if (auth?.token) {
      fetchResponse(endpoints.logout, "GET", {}, { 'Authorization': `Bearer ${auth.token}` })
        .then(() => {
          resetAuth(callbackUrl);
        }).catch(err => {
          console.error('170', err.message);
          resetAuth(callbackUrl);
        });
    }
    else {
      resetAuth(callbackUrl);
    }

  };


  const resetAuth = (callbackUrl) => {
    // console.warn("249/authContext resetAuth()");

    // clear only compulsory keys
    window.localStorage.removeItem("auth");
    window.localStorage.removeItem("facilities");
    window.localStorage.removeItem("selectedFacility");

    window.localStorage.removeItem("fcmDeviceToken");
    window.localStorage.removeItem("APP_CONTEXT_VALUES");

    setAuth(null);

    // // redirect
    setTimeout(() => {
      // console.warn("262/useAuth reloading");
      window.location = callbackUrl || PATH_AUTH.login;
      // navigate(PATH_AUTH.login);
    }, 50);
  };
  // --------- login logout --------



  // --------- Facility --------
  const fetchFacility = () => {
    fetchResponse(endpoints.facility.find, "POST", { software_admin: auth.user_id })
      .then((response) => {
        // fetch facilities
        // select the first one
        const { data } = response;

        if (data.length > 0) {
          const _facilities = data.map(e => ({ ...DEFAULT_FACILITY, ...e }))
          setFacilities(_facilities);

          if (!selectedFacility) {
            setSelectedFacility({ ...DEFAULT_FACILITY, ..._facilities[0] });
          }
        }

      }).catch(err => {
        console.error('loger114', err.message);
      });
  }

  const createFacility = async (payload) => {
    payload.software_admin = auth.user_id;

    await fetchResponse(endpoints.facility.create, "POST", payload, { 'Authorization': `Bearer ${auth.token}` })
      .then((response) => {
        // fetch facilities
        // select the first one
        const { data } = response;

        setFacilities(prev => {
          if (!selectedFacility) {
            setSelectedFacility({ ...DEFAULT_FACILITY, ...data });
          }

          enqueueSnackbar('Facilty created', { variant: 'success' });

          // fetch facilities
          fetchFacility();

          return [...(prev || []), data];
        });

      }).catch(err => {
        console.error('loger242', err.message);
      });
  }
  // --------- Facility --------





  useEffect(() => {
    if (!auth) {
      return;
    }

    // console.log('loger/ac/108 auth changed', auth);
    if (auth.user_type === "SOFTWARE_ADMIN") {
      fetchFacility();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const authContextProviderValue = useMemo(() => ({
    // ...state,

    // TODO, this is of no use
    user: {
      token: auth?.token,
      photoURL: "",
      email: "",
      name: auth?.name,
      user_id: auth?.user_id,
      phone_number: auth?.phone_number,
      user_type: auth?.user_type,
    },

    auth,
    setAuth,

    getOTP,
    resendOTP,
    verifyOTP,

    // login,
    logout,
    resetAuth,

    facilities,
    selectedFacility,
    setSelectedFacility,
    createFacility,
  }),
    // eslint-disable-next-line
    [
      auth,
      facilities,
      selectedFacility,
    ]);

  return (
    <AuthContext.Provider
      value={authContextProviderValue}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
