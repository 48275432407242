const ROOTS_AUTH = '/auth';
// const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user';
const ROOTS_APP = '/';
export const ROOTS_TEST = '/test';
export const PATH_AFTER_LOGIN = '/dashboard';

// -----------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verifyOTP: path(ROOTS_AUTH, '/verify'),
  logout: '/logout',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const URLS = {
  root: ROOTS_APP,
  dashboard: 'ticket/dashboard',
  groups: {},
  ticket: {
    root: '/ticket',
    create: '/ticket/create',
    success: '/ticket/success',
    settings: '/ticket/settings',
    group: '/ticket/group',
    dashboard: '/ticket/dashboard',
  },
  task: {
    root: '/task',
    create: '/task/create',
    group: '/task/group',
    groups: '/task/dashboard',
    stats: '/task/stats',
    sareports: '/sa/issue/reports',
    sareporttile: '/sa/issue/reporttile',
    sadashboard: '/sa/issue/dashboard',
  },
  assettask: {
    taskdetails: '/assettask/taskdetails',
  },
  zapx: {
    root: '/zapx',
    orderdashboard: '/zapx/orderdashboard',
    dietitian: {
      home: '/zapx/home',
      settings: '/zapx/settings',
      configuration: '/zapx/configuration',
      createPatient: '/zapx/patient/create',
      foodScore: '/zapx/foodscore',
      dischargedPatients: '/zapx/dispatients',
    },
    dietchart: {
      root: '/zapx/dietchart',
      add: '/zapx/dietchart/add',
      edit: '/zapx/dietchart/edit',
    },
    comorbidities: {
      root: '/zapx/comorbidities',
    },
    feedtype: {
      root: '/zapx/feedtype',
    },
    nbmnpo: {
      root: '/zapx/nbmnpo',
    },
    diagnosisnames: {
      root: '/zapx/diagnosisnames',
    },
    clinicalparameter: {
      root: '/zapx/clinicalparameter',
    },
    dietarypreferences: {
      root: '/zapx/dietarypreferences',
    },
    assessment:{
      root: '/zapx/assessment',
      add: '/zapx/assessment/add',
    },
    patientcategory: {
      root: '/zapx/patientcategory',
    },
    diettypes: {
      root: '/zapx/diettypes',
      add: '/zapx/diettypes/add',
      edit: '/zapx/diettypes/edit',
    },
    liquiddiettypes: {
      root: '/zapx/liquiddiettypes',
      add: '/zapx/liquiddiettypes/add',
      edit: '/zapx/liquiddiettypes/edit',
    },
    mastermenu: {
      root: '/zapx/mastermenu',
      add: '/zapx/mastermenu/add',
      edit: '/zapx/mastermenu/edit',
    },
    foodallergy: {
      root: '/zapx/foodallergy',
      add: '/zapx/foodallergy/add',
      edit: '/zapx/foodallergy/edit',
    },
    cafeteria: {
      root: '/zapx/cafeteria',
      add: '/zapx/cafeteria/add',
      edit: '/zapx/cafeteria/edit',
      category: {
        add: '/zapx/cafeteria/category/add'
      }
    },
    patient: {
      root: '/zapx/profile',
      edit: '/zapx/profile/edit',
      assessment: '/zapx/profile/assessment',
      dietprofile: '/zapx/profile/dietprofile',
    },
    common: {
      kitchenqr: '/zapx/kitchenqr',
      locationqr: '/zapx/locationqr'
    },
    kitchenManager: {
      // kitchenqr: '/zapx/km/kitchenqr',
    },
    // FNBManager: {
    //   dashboard: '/zapx/fnb/dashboard',
    // },
    DelSteward: {
      home: '/zapx/ds/home',
    },
   
    cafeteriaMenu: {
      root: '/zapx/cafeteriamenu',
      add: '/zapx/cafeteriamenu/add',
    },
    manageMeal: {
      root: '/meals',
      order: '/meals/order',
      cart: '/meals/cart',
      payment: '/meals/payment',
      track: '/meals/track',
      status: '/meals/status',
    }
  },
  scan: '/scan',
  ctterms: '/ctterms',
  general: {
    admin: '/admin',
    settings: '/settings',
  },

  user: {
    root: ROOTS_USER,
    profile: path(ROOTS_USER, '/profile'),
    account: path(ROOTS_USER, '/account'),
  },

  admin: {
    root: '/admin',
    dashboard: '/admin/dashboard',
    reports: '/admin/reports',
    reporttile: '/admin/reporttile',
    manageGroup: '/admin/group',
    etrSettings: '/admin/etr-settings',
    generateQR: '/admin/generate-qr',
    users: '/admin/users',
    usersactivity: '/admin/usersactivity',
    userProfile: '/admin/user-profile',
    tickets: '/admin/tickets',
    tasks: '/admin/tasks',
    checklists: '/admin/checklists',
    deletedchecklist: '/admin/deletedchecklists',
    checkpoints: '/admin/checkpoints',
    categories: '/admin/categories',
    assets: '/admin/assets',
    assetclasses: '/admin/assetclasses',
    assetsettings: '/admin/atsettings',
    tsettings: '/admin/tsettings',
    assettasks: '/admin/assettasks',
  },

  test: {
    root: ROOTS_TEST,
    components: path(ROOTS_TEST, '/components'),
    allComponents: path(ROOTS_TEST, '/all-components'),
    pages: path(ROOTS_TEST, '/pages'),
  },
};

export const PATH_DOCS = '';

function path(root, sublink) {
  return `${root}${sublink}`;
}
