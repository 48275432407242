// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

import { getPerformance } from "firebase/performance";

import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";

import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";

// -----------------------------------------------
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// -----------------------------------------------
// Initialize Firebase
export const app  = process.env.REACT_APP_ENV === 'dev' ?
    console.log("Firebase disabled Dev mode"): initializeApp(firebaseConfig);

export const vapIdKey  = process.env.REACT_APP_ENV === 'dev' ?
    console.log("Firebase disabled Dev mode"): process.env.REACT_APP_FIREBASE_MSG_KEY;

// -----------------------------------------------
// Initialize Analytics
export const analytics = process.env.REACT_APP_ENV === 'dev' ?
    console.log("Firebase disabled Dev mode"): getAnalytics(app);

// -----------------------------------------------
// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = process.env.REACT_APP_ENV === 'dev' ?
    console.log("Firebase disabled Dev mode"): getMessaging(app);

export const isFirebaseMsgSupported = process.env.REACT_APP_ENV === 'dev' ?
    console.log("Notifications not supported in local"):isSupported();

export const fetchToken = () =>
  getToken(messaging, { vapidKey: vapIdKey }).then((currentToken) => {
    if (currentToken) {
      // console.log('current token for client: ', currentToken);
      return currentToken;
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    }
    console.log('No registration token available. Request permission to generate one.');
    return undefined;
    // shows on the UI that permission is required 
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });


export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

// -----------------------------------------------
// -----------------------------------------------

// Initialize Remote Config and get a reference to the service
export const remoteConfig = process.env.REACT_APP_ENV === 'dev'?
    console.log("Disabled in dev mode"):getRemoteConfig(app)

if (remoteConfig){
    remoteConfig.settings = {
        minimumFetchIntervalMillis: 3600000, // 60 min / 1 Hour
    }
}

// fetch and activate values
export const userIdHashKey = process.env.REACT_APP_ENV ==='dev'?
    console.log("Disabled in dev mode"):fetchAndActivate(remoteConfig)
  .then(() => {
    // get remote config value
    const hashKey = getValue(remoteConfig, "hash_key").asString();
    // console.log('hashKey: ', JSON.stringify(hashKey))
    return hashKey;
  })
  .catch((err) => {
    console.log(err)
  });

// Initialize Performance Monitoring and get a reference to the service
// eslint-disable-next-line no-unused-vars
const perf = process.env.REACT_APP_ENV ==='dev'?
    console.log("performance disabled in dev"):getPerformance(app);

